<!-- 待办任务 by阿旭 -->
<template> 
  <div>
    <div v-if="!isShowDetail">
      <el-tabs v-model="activeName" @tab-click="tabhandleClick">

        <!-- 核酸检测 -->
        <el-tab-pane label="核酸检测" name="second">
          <el-row type="flex" justify="space-between" align="middle">
            <el-col :span="22">
              <el-input style="width:230px;margin-right:20px" v-model="hs.searchValue" placeholder="居民姓名、证件号码、手机号码">
              </el-input>
              <el-select style="width:230px;margin-right:20px" v-model="hs.crowdTypeId" clearable placeholder="人群分类">
                <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"
                  :value="item.id"></el-option>
              </el-select>
              <el-select style="width:140px;margin-right:20px" v-model="hs.status" clearable placeholder="采样状态">
                <el-option v-for="item in rwoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select style="width:140px;margin-right:20px" v-model="hs.controlMethod" clearable placeholder="管控类型">
                <el-option v-for="item in zkrwoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-date-picker v-model="timetime" size="medium" type="daterange" value-format="yyyy-MM-dd"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
            </el-col>
            <el-col :span="4" style="text-align:right">
              <el-button type="primary" @click="queryTask('btn')">查询</el-button>
              <el-button type="primary" @click="exportFormInfo" v-loading.fullscreen.lock="fullscreenLoading">导出
              </el-button>
            </el-col>
          </el-row>
          <div class="top_table">
            <el-table :data="tableData" style="width: 100%" height="800" @selection-change="handleCovidSelectionChange">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column fixed="left" label="操作" width="140">
                <template slot-scope="scope">
                  <!-- 取消和完成状态无此操作按钮 -->
                  <el-button @click="distribute(scope.row)" type="text" size="small">
                    录入</el-button>
                  <el-button @click="openSamplingMode(scope.row)" type="text" size="small">打印采样条码
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名"></el-table-column>
              <el-table-column prop="cardNo" label="证件号码" width="200"></el-table-column>

              <el-table-column prop="crowdTypeName" label="人群分类" width="180"></el-table-column>
              <el-table-column prop="country" label="国籍" width="180"></el-table-column>
              <el-table-column prop="barCode" label="条形码" width="180"></el-table-column>
              <el-table-column prop="controlMethod" label="管控类型" width="180">
                <template slot-scope="scope">
                   <span style="background-color: #409eff;color: #fff;padding: 0px 10px;border-radius: 10px; float: left" v-if="scope.row.controlMethod == 1">{{
                       scope.row.controlMethod |
                           typerw
                     }}</span>
                  <span style="background-color: #f56c6c;color: #fff;padding: 0px 10px;border-radius: 10px; float: left" v-if="scope.row.controlMethod != 1">{{
                      scope.row.controlMethod | typerw
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="currentAddressDetail" label="隔离地址" width="220">
                <template  slot-scope="scope">
                  <span  v-if="scope.row.currentAddress !=null" >{{  scope.row.currentAddress}}</span>
                  <span  v-if="scope.row.currentAddressDetail !=null" >{{  scope.row.currentAddressDetail}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="startTime" label="开始隔离时间" width="180"></el-table-column>
              <el-table-column prop="status" label="状态" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 0">{{ scope.row.status | astatus }}</span>
                  <span v-if="scope.row.status == 1">{{ scope.row.status | astatus }}</span>
                  <span v-if="scope.row.status == 2">{{ scope.row.status | astatus }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="samplingMode" label="采样形式" width="120"></el-table-column>
              <el-table-column prop="times" label="采样次数" width="120"></el-table-column>
              <el-table-column prop="collectTime" label="采样时间" width="180"></el-table-column>
              <el-table-column prop="labResult" label="检测结果" width="120"></el-table-column>
            </el-table>
          </div>
          <div class="botommbox"></div>
          <div class="bottom-fix">
            <div class="content-page-four">
              <div class="operation-text">
                <span>已选中{{ multipleSelection.length }}项目</span>
                <el-button type="primary" size="mini" style="margin-left:20px"
                    @click="multipleOpenSamplingMode()"  :disabled="multipleSelection == 0"
                >批量打印采样条码</el-button>
              </div>
            </div>

            <div class="content_page">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pageNumber" :total="total" :page-sizes="[10, 20, 40, 60]" :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper" />
            </div>
          </div>
        </el-tab-pane>

        <!-- 社区管理-代办任务-解除隔离标签页 -->
        <el-tab-pane label="解除隔离" name="third">
          <el-row type="flex" justify="space-between" align="middle">
            <el-col :span="22">
              <el-input style="width:230px;margin-right:20px" v-model="jc.searchValue" placeholder="居民姓名、证件号码、手机号码">
              </el-input>
              <el-select style="width:230px;margin-right:20px" v-model="jc.crowdTypeId" clearable placeholder="人群分类">
                <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"
                  :value="item.id"></el-option>
              </el-select>
              <el-select style="width:140px;margin-right:20px" v-model="jc.controlMethod" clearable placeholder="管控类型">
                <el-option v-for="item in zkrwoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-date-picker v-model="timetime2" size="medium" type="daterange" value-format="yyyy-MM-dd" @change="handlechangeriqi"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
            </el-col>

            <el-col :span="2" style="text-align:right">
              <el-button type="primary" @click="queryTask02('btn')">查询</el-button>
            </el-col>
          </el-row>
          
          <!-- 社区管理-代办任务-解除隔离 -->
          <div class="top_table">
            <el-table :data="tableData02" style=" width: 100%" height="700">
              <el-table-column fixed="left" label="操作" width="200">
                <template slot-scope="scope">
                  <!-- 取消和完成状态无此操作按钮 -->
                  <el-button @click="distribute02(scope.row)" type="text" size="small">
                    解除隔离</el-button>
                  <el-button @click="qxaaaa(scope.row)" type="text" size="small">查看更多
                  </el-button>

                </template>
              </el-table-column>

              <el-table-column prop="name" label="姓名" width="200"></el-table-column>
              <el-table-column prop="cardNo" label="证件号码" width="180"></el-table-column>
              <el-table-column prop="crowdTypeName" label="人群分类" width="150"></el-table-column>
              <el-table-column prop="country" label="国籍" width="120"></el-table-column>

              <el-table-column prop="controlMethod" label="管控类型" width="120">

                <template slot-scope="scope">
                   <span style="background-color: #409eff;color: #fff;padding: 0px 10px;border-radius: 10px; float: left" v-if="scope.row.controlMethod == 1">{{
                       scope.row.controlMethod |
                           typerw
                     }}</span>
                  <span style="background-color: #f56c6c;color: #fff;padding: 0px 10px;border-radius: 10px; float: left" v-if="scope.row.controlMethod != 1">{{
                      scope.row.controlMethod | typerw
                    }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="currentAddressDetail" label="隔离地址" width="300">
                <template  slot-scope="scope">
                  <span  v-if="scope.row.currentAddress !=null" >{{  scope.row.currentAddress}}</span>
                  <span  v-if="scope.row.currentAddressDetail !=null" >{{  scope.row.currentAddressDetail}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="startTime" label="开始隔离时间" width="200"></el-table-column>
              <el-table-column prop="endTime" label="预解除隔离时间" width="200"></el-table-column>
            </el-table>
            <div class="bottom-fix">
              <div class="content_page">
                <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
                  :current-page="pageNumber2" :total="total2" :page-sizes="[10, 20, 40, 60]" :page-size="pageSize2"
                  layout="total, sizes, prev, pager, next, jumper" />
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
    <!-- 更改负责部门 -->
    <el-dialog title="更改负责部门" :visible.sync="ischangeResponsible" width="50%">
      <div>
        社区卫生服务中心：<el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" filterable :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ischangeResponsible = false">取 消</el-button>
        <el-button type="primary" @click="ischangeResponsible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 核酸检测采样方式 -->
    <el-dialog title="核酸检测采样方式" :visible.sync="isnucleicAcid" width="50%">
      <div>
        采样方式：<el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" filterable :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isnucleicAcid = false">取 消</el-button>
        <el-button type="primary" @click="isnucleicAcid = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 录入核酸结果 -->
    <el-dialog title="录入核酸结果" :visible.sync="isentenucleiaciresults" width="50%">
      <div>
        <el-form :model="enterForm" :rules="enterFormrules" ref="enterFormruleForm" label-width="100px"
          class="demo-ruleForm">
          <el-form-item label="采样时间" required prop="collectTime">
            <!-- <el-date-picker type="date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"
              v-model="enterForm.collectTime" style="width: 100%;">
            </el-date-picker> -->

            <el-date-picker style="width: 280px" class="top_left_checktime" type="datetime"
              v-model="enterForm.collectTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择时间"></el-date-picker>

          </el-form-item>

          <el-form-item label="采样结果" prop="labResult">
            <el-radio-group v-model="enterForm.labResult">
              <el-radio label="阴性"></el-radio>
              <el-radio label="阳性"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="采样机构" prop="labOrg">
            <el-input v-model="enterForm.labOrg"></el-input>
          </el-form-item>
          <el-form-item label="报告时间" required prop="reportTime">
            <!-- <el-date-picker type="date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"
              v-model="enterForm.reportTime" style="width: 100%;">
            </el-date-picker> -->

            <el-date-picker style="width: 280px" class="top_left_checktime" type="datetime"
              v-model="enterForm.reportTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择时间"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isentenucleiaciresults = false">取 消</el-button>
        <el-button type="primary" @click="acccs">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 解除隔离弹窗 -->
    <el-dialog title="解除隔离" :visible.sync="isjcgl" width="50%">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item label="转出类型" prop="transferType">
            <el-radio-group @change="trasHome()" v-model="ruleForm.transferType">
              <el-radio label="1">解除管控</el-radio>
              <el-radio label="2">送医治疗</el-radio>
              <el-radio label="3" :disabled="this.unselect === '3'">送往隔离点</el-radio>
              <el-radio :disabled="this.unselect === '4'" label="4">居家隔离</el-radio>
              <el-radio :disabled="this.unselect === '5'" label="5">居家监测</el-radio>
              <el-radio label="9">其他</el-radio>
            </el-radio-group>
          </el-form-item>

          <P style="color:red; margin-bottom: 20px;margin-left:100px" v-if="this.tipsList.length>0&&this.ruleForm.transferType !== ''">{{this.tipsList.find(item =>{
           return item.transferType === this.ruleForm.transferType
         }) ? this.tipsList.find(item =>{
          return item.transferType === this.ruleForm.transferType
        }).tips : ''}}</P>

          <el-form-item label="转出目的地：" prop="transferDestination">
            <el-input v-if="ruleForm.transferType==''||ruleForm.transferType==null" placeholder="请输入" />
            <el-input v-if="ruleForm.transferType == 1||ruleForm.transferType ==2||ruleForm.transferType ==9" style="width:90%" v-model="ruleForm.transferDestination" placeholder="请输入" />

            <el-select v-if="ruleForm.transferType == 4||ruleForm.transferType ==5" clearable filterable size="medium" v-model="homeitem" @change="homeOrgChange($event)" placeholder="负责机构">
                    <el-option v-for="(item,index) in atHomeList" :key="index" :label="item.orgName" :value="item.orgCode" />
              </el-select>

              <el-select v-if="ruleForm.transferType == 3" clearable filterable size="medium" v-model="isolationOrgItem"
              @change="isolationOrgChange($event)" placeholder="隔离点">
              <el-option v-for="(item,index) in isolationOrg" :key="index" :label="item.orgName" :value="item.orgCode" />
              </el-select>

</el-form-item>



          <el-form-item label="备注">
            <el-input v-model="ruleForm.transferRemark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isjcgl = false">取 消</el-button>
        <el-button type="primary" @click="relive">确 定</el-button>
      </span>
    </el-dialog>
    
    <el-dialog title="核酸检测采样方式" :visible.sync="samplingMode" width="25%">
      <el-form :model="samplingForm">
        <el-form-item label="采样时间" :label-width="formLabelWidth" prop="samplingTime" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-date-picker class="select-width" type="datetime"
                          v-model="samplingForm.samplingTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="选择时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="采样方式" :label-width="formLabelWidth">
          <el-select class="select-width" size="medium" v-model="samplingForm.samplingMode" placeholder="请选择核酸检测采样方式">
            <el-option v-for="item in samplingModeList" :key="item.dictSort" :label="item.dictLabel"
              :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="samplingMode = false">取 消</el-button>
        <el-button type="primary" @click="dictionaryFun3()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="核酸检测采样方式" :visible.sync="multipleSamplingMode" width="25%">
      <el-form :model="samplingForm"  ref="multipleSamplingForm">
        <el-form-item label="采样时间" :label-width="formLabelWidth" prop="samplingTime" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-date-picker class="select-width" type="datetime"
                          v-model="samplingForm.samplingTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="选择时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="采样方式" :label-width="formLabelWidth" prop="samplingMode" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-select class="select-width" size="medium" v-model="samplingForm.samplingMode" placeholder="请选择核酸检测采样方式">
            <el-option v-for="item in samplingModeList" :key="item.dictSort" :label="item.dictLabel"
                       :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="multipleSamplingMode = false">取 消</el-button>
        <el-button type="primary" @click="multipleSamplingFun('multipleSamplingForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import residentInformation from "@/views/QuarantineRegistration/residentInformation";
import TableModel from "@/components/TableModel";
import { mapState } from "vuex";
import {
  homeList,
  exportCovidTask,
  relive,
  collectDetect,
  toduHomeDeisolationList
} from "@/api/Taskmanagement/upcomingTasks.js";
import constants from "@/common/constants";
import { SURVEY_RESULT } from "@/common/constants";
import {batchPrintBarcode, importFile, messageBox, printBarcode} from "../../utils/utils";
import DialogInfo from "@/components/DialogInfo.vue"
import {
  batchPrintCovidBarCode,
  printCovidBarCode,
  samplingModeList,
  reliveControlTips
} from "../../api/QuarantineRegistration/quarantineRegistrationpop";
import { getBusinessOrgList,getAllIsolationOrgList} from "../../api/SystemManagement/index.js"; //根据业务状态查询机构
import moment from "moment";
var timer = null;
export default {
  components: { residentInformation, TableModel, DialogInfo },
  data() {
    return {
      transferTypeOptions: [
        { label: '解除管控', type: '1' },
        { label: '送医治疗', type: '2'},
        { label: '送往隔离点', type: '3'},
        { label: '居家监测', type: '4'},
        { label: '居家隔离', type: '5'},
        { label: '其他', type: '9'},
      ],
      isolationOrgItem:"", //隔离机构的
      isolationOrg:[], //获取所有隔离酒店机构列表
      atHomeList:[], //居家的机构
      homeitem:"", //选择的居家机构
      tipsList:[], //提示语
      unselect:'', //不能被选中的
      select:"",
      typeCertificate: constants.TYPE_CERTIFICATE.filter((item, i) => i !== 0), //身份证类型
      activeName: "second",
      samplingMode: false,
      fullscreenLoading: false,
      id: "",
      isShowDetail: false,
      ischangeResponsible: false,
      taskStatus: 1,
      samplingForm: {
        samplingMode: "",
        samplingTime: new Date(),
        regId: "",
        taskId: ""
      },
      multipleSelection: [], // 勾选选项id数组，批量操作解除隔离
      multipleSamplingMode: false,
      isentenucleiaciresults: false,
      isjcgl: false,
      ruleForm: {
        transferDestination:"", //转出机构名称
        transferDestinationCode:"", //转出机构编码
        transferType: "", //转出类型
        transferRemark:"" //备注
      },
      isjjjc: false,
      isjjgl: false,
      jmname: "",
      formLabelWidth: "120px",
      enterForm: {},
      searchValue: "",
      samplingModeList: [],
      timevalue: ["", ""],
      isnucleicAcid: false,
      isinput: false,
      sqryoptions: "",
      sddata: [],
      options: [],
      value1: "",
      taskCancelReason: "",
      timetime: [],
      value: "",
      tableData02: [],
      tableData: [],
      expands: [],
      jc: {},
      rwoptions: [
        {
          label: "全部",
          value: ""
        },
        {
          label: "已采样",
          value: 1
        },
        {
          label: "已报告",
          value: 2
        },
        {
          label: "未采集",
          value: 0
        }
      ],
      zkrwoptions: [
        {
          label: "全部",
          value: ''
        },
        {
          label: "居家隔离",
          value: 2
        },
        {
          label: "居家监测",
          value: 1
        },

      ],
      loading: false,
      hs: {},
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      pageNumber: 1,
      pageNumber2: 1,
      pageSize: 10,
      pageSize2: 10,
      lrid: "",
      total: 0,
      total2: 0,
      enterFormrules: {
        collectTime: [
          { required: true, message: '请选择采样时间', trigger: 'blur' },
        ],
        reportTime: [
          { required: true, message: '请选择报告时间', trigger: 'blur' },
        ],
        labResult: [
          { required: true, message: '请选择采样结果', trigger: 'blur' },
        ],
        labOrg: [
          { required: true, message: '请输入采样机构', trigger: 'blur' },
        ],
      },
      rules: {
        transferDestination: [
          { required: true, message: '请输入转出目的地', trigger: 'blur' },
        ],
        transferType: [
          { required: true, message: '请选择转出类型', trigger: 'blur' },
        ],
      },
      timetime2:[],
    };
  },
  filters: {
    typeid(val) {
      if (val == 1) {
        val = "居民身份证";
        return val;
      } else if (val == 2) {
        val = "护照";
        return val;
      } else if (val == 3) {
        val = "港澳通行证";
        return val;
      } else if (val == 4) {
        val = "台胞证";
        return val;
      } else if (val == 5) {
        val = "其他";
        return val;
      }
    },
    typerw(val) {
      if (val == 1) {
        val = "居家监测";
        return val;
      } else {
        val = "居家隔离";
        return val;
      }
    },
    astatus(val) {
      if (val == 1) {
        val = "已采集";
        return val;
      } else if (val == 2) {
        val = "已报告";
        return val;
      } else if (val == 0) {
        val = "未采集";
        return val;
      }
    },
    sex(val) {
      if (val == 1) {
        val = "男";
        return val;
      } else {
        val = "女";
        return val;
      }
    },
  },
  computed: {
    ...mapState({
      typepopSelect: (state) => state.user.typepopSelect, // 人群分类
    }),
  },
  created() {
    this.surveyResult = SURVEY_RESULT;
    this.initTime();
    this.queryTask('btn');
    this.samplingModeListFun(); //核酸检测采样方式
    this.getAllIsolationOrgList(); //获取所有隔离酒店机构列表
    this.homeOrgList();//查询对应的居家机构
  },
  mounted() {
    // console.log(this.timetime);
  },
  methods: {
    // 初始化时间
    initTime() {
      let day = moment().format("YYYY-MM-DD");
      let tomorrow  = moment(new Date()).add(1,'days').format("YYYY-MM-DD");
      // console.log(tomorrow,555555555);
      this.timetime = [day, day];
      this.timetime2 = [day,tomorrow]
    },
    // 切换radio按钮清空选项
    trasHome(){
        this.homeitem = "";
        this.ruleForm.transferDestination = "";
        this.isolationOrgItem = ""
    },
    // 根据业务状态去查询机构()居家
    async homeOrgList() {
      let params = { homeIsolation: 1 }
      let {data} = await getBusinessOrgList({ params });
      this.atHomeList = data.data;
    },
    // 获取所有隔离酒店机构列表
    async getAllIsolationOrgList(){
        let res = await getAllIsolationOrgList();
        this.isolationOrg = res.data.data;
    },

    homeOrgChange(orgCode){
      // 获取当前选择的居家的机构
      let dir = this.atHomeList.find((item) => item.orgCode === orgCode);
      this.ruleForm.transferDestination = dir.orgName;
      this.ruleForm.transferDestinationCode = dir.orgCode;
    },

    isolationOrgChange(orgCode){
      // 获取当前选择的隔离点的机构
      let dir = this.isolationOrg.find((item) => item.orgCode === orgCode);
      // console.log(dir,'打印dirrrr')
      this.ruleForm.transferDestination = dir.orgName;
      this.ruleForm.transferDestinationCode = dir.orgCode;
    },

    // 核酸检测采样方式列表
    async samplingModeListFun() {
      samplingModeList().then(res => {
        if (res.data.code === "200") {
          this.samplingModeList = res.data.data;
        }
      });
    },
    tabhandleClick(tab, event) {
      if (tab.index == 0) {
        this.queryTask('btn')
      } else {
        this.queryTask02('btn')
      }
    },
    // 查询待处理任务
    async queryTask(type) {
      if (type == 'btn') {
        this.pageNumber = 1;
      }
      let a, b;
      if (this.timevalue == null) {
        this.timevalue = [];
      } else {
        if (this.timevalue[0]) {
          a = this.timevalue[0];
          b = this.timevalue[1];
        }
      }

      let params = {
        ...this.hs,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      if (this.timetime && this.timetime.length == 2) {
        params.collectDateMin = this.timetime[0] + " 00:00:00";
        params.collectDateMax = this.timetime[1] + " 23:59:59";
      }
      const { data } = await homeList({ params })
      if (data.code == '200') {
        this.tableData = data.data.list;
        this.total = parseInt(data.data.totalRow);
      }
    },
    //待处理任务的分配按钮
    distribute(row) {
      this.isentenucleiaciresults = true
      this.lrid = row.id
    },
    qxaaaa(row) {
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row);
      });
    },
    //分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryTask();
    },
    handleSizeChange2(val) {
      this.pageSize2 = val;
      this.queryTask02();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.queryTask();
    },
    handleCurrentChange2(val) {
      this.pageNumber2 = val;
      this.queryTask02();
    },
    // 更改负责部门
    changeResponsible() {
      this.ischangeResponsible = true
    },
    // 导出
    exportFormInfo() {
      let params = {
        ...this.hs,
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
      };
      if (this.timetime && this.timetime.length == 2) {
        params.collectDateMin = this.timetime[0] + " 00:00:00";
        params.collectDateMax = this.timetime[1] + " 23:59:59";
      }
      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportCovidTask(params).then((res) => {
            this.fullscreenLoading = false;
            importFile(res.data, "待办任务核酸信息");
          });
        },
        "warning",
        "是否确认导出 待办任务核酸 数据？"
      );
    },
    handlechangeriqi(){
      this.queryTask02()
    },
    async queryTask02() {
      let params = {
        ...this.jc,
        startTimeMin:this.timetime2[0],
        startTimeMax:this.timetime2[1],
        pageNumber: this.pageNumber2,
        pageSize: this.pageSize2,
      };
      try {
        let { data } = await toduHomeDeisolationList({ params });
        // console.log(data,'输出data55555555');
        if (data.code == 200) {
          this.tableData02 = data.data.list;
          this.total2=data.data.totalRow;

        }
      } catch (error) { }
    },
    async relive() {
      let params = {
        ...this.ruleForm,
        id: this.id, //选中的当前的人的id
      };
      try {
        let { data } = await relive(params);
        if (data.code == 200) {
          // _this.tableData = data.data;
          this.$message({
            message: "解除隔离成功！",
            type: "success"
          });
          this.queryTask('btn')
         
        }
      } catch (error) { }

      this.isjcgl = false

    },
    async distribute02(row) {
      // console.log(row,'打印居家 待办任务 选中的人的信息（预解除隔离）')
      // 清空选项
        this.homeitem = "";
        this.ruleForm.transferType = '1'
        this.ruleForm.transferDestination = "";
        this.isolationOrgItem = "";
        this.ruleForm.transferRemark = '';
      
      // 查询提示语
      let params = {
            id:row.id,
            source: "2"
        }
        let res = await reliveControlTips ({params});
        // console.log(res,'打印居家这边的待办任务！提示语返回的res')
        this.tipsList = res.data.data.tips; //获取到的提示语数组
        this.unselect = res.data.data.unselect; //不选中类型
        this.select = res.data.data.select
        // console.log(this.select,'this.select打印，有可能是数字，有可能为null')

      this.id = row.id
      
      if (row.controlMethod == 1) {

        this.isjjjc = true
      } else {
        this.isjjjc = false
      }
      if (row.controlMethod == 2) {
        // this.ruleForm.transferType = '1'
        this.isjjgl = true
      } else {
        this.isjjgl = false
      }
      this.isjcgl = true

    },
    async acccs() {
      let params = {
        ...this.enterForm,
        id: this.lrid,
        regId: this.lrid,
      };
      try {
        let { data } = await collectDetect(params);
        if (data.code == 200) {
          this.$message({
            message: "录入成功！",
            type: "success"
          });

        }
      } catch (error) { }

      this.isentenucleiaciresults = false
    },
    //打开核酸检测类型
    openSamplingMode(row) {
      if (row.status === "0") {
        this.samplingForm.regId = row.id;
        this.samplingForm.taskId = row.taskId;
        this.samplingMode = true;
      } else {
        let params = {
          regId: row.id,
          taskId: row.taskId
        };
        this.dictionaryFun(params);
      }
    },
    // 打印条码
    async dictionaryFun(params) {
      let { data } = await printCovidBarCode({ params });
      if (data.code === "200") {
        printBarcode(data.data);
      }
    },
    async dictionaryFun3() {
      let params = this.samplingForm;
      params.samplingTime = moment(this.samplingForm.samplingTime).format("YYYY-MM-DD HH:mm:ss");
      let { data } = await printCovidBarCode({ params });
      if (data.code === "200") {
        this.samplingMode = false;
        this.samplingForm.samplingTime = new Date();
        this.samplingForm.samplingMode = "";
        printBarcode(data.data);
      } else {
        this.$message.error(data.msg);
      }
    },

    // 核酸检测全选中
    handleCovidSelectionChange(arrval) {
      // 把选中的id push到multipleSelection数组
      this.multipleSelection = arrval;
    },
    //批量打开核酸检测类型
    multipleOpenSamplingMode() {
      if(this.multipleSelection.length > 0 ){
        this.multipleSamplingMode = true;
      }else{
        this.$message.warning("请选择打印条码数据");
      }

    },
    //批量打印核酸检测条码
    async multipleSamplingFun(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.multipleSamplingMode = false;

          let params = {
            printBusinessType:"2",
            samplingMode: "",
            samplingTime: "",
            printCovidRegList: []
          };
          params.samplingMode = this.samplingForm.samplingMode;
          params.samplingTime = moment(this.samplingForm.samplingTime).format("YYYY-MM-DD HH:mm:ss");

          for (let i in this.multipleSelection) {
            let covidId = this.multipleSelection[i];
            if(covidId.id){
              let printCovidReg = {
                regId: "",
                taskId: ""
              };
              printCovidReg.regId = covidId.id;
              printCovidReg.taskId = covidId.taskId;
              params.printCovidRegList.push(printCovidReg);
            }

          }
          this.batchPrintCovidBarCodeFun(params);
        } else {
          return false;
        }
      });
    },
    // 批量打印条码
    async batchPrintCovidBarCodeFun(params) {
      let { data } = await batchPrintCovidBarCode(params);
      if (data.code === "200") {
        batchPrintBarcode(data.data);
      }
    },

  }
};
</script>
<style lang='scss' scoped>
.top_input {
  display: flex;
}

.top_table {
  margin-top: 10px;
  margin-bottom: 20px;
}

.top_operate {
  display: flex;
  align-items: center;
}

.dialog_msg {
  display: flex;
  margin-bottom: 10px;
}

.dialog_msg_name {
  margin-right: 14px;


  .dialog_msg_name_id {
    padding: 4px;
    color: #999999;
  }
}



.dialog_msg_type {
  margin-right: 14px;
  padding: 4px;
  background-color: #F90101;
  color: #fff;
}


.dialog_msg_type02 {
  margin-right: 14px;
}


.dialog_msg_typebsmj {
  margin-right: 14px;
  padding: 4px;
  color: #fff;
  background-color: #0079FE;
}

table {
  border-collapse: collapse;
}

table tr td {
  border: 1px solid #d4dedd;
}

.td1 {
  padding: 0 10px;

  span {
    background-color: #0079fe;
    color: #fff;
  }
}

.td0,
.td2,
.td3,
.td4,
.td5,
.td6 {
  padding: 10px 10px;
}

.td6 {
  display: flex;
}

.botommbox{
  height: 50px;
}

.bottom-fix{
  width: 100%;
  background-color: rgb(255, 255, 255);
  position: fixed;
  bottom: -40px;
  height: 100px;
  z-index: 99;
  .content_page {
  background-color:rgb(255, 255, 255);
  position: fixed;
  // bottom: 20px;
  right: 0px;
  // margin-top: -30px;
  height: 40px;
  float: right;
}
.content-page-four{
  float: left;
  background-color: rgb(255, 255, 255);
  width: 400px;
  position: relative;
  left: 0px;
  margin-top: 10px;
}
}
</style>